import React, { Component } from 'react'
import { navigate } from 'gatsby'

import { CustomPagination } from './Pagination.styled'

class Pagination extends Component {
  handleOnChange = current => {
    const { location } = this.props
    const prefix = location.pathname.substring(
      0,
      location.pathname.lastIndexOf('/')
    )
    const url = `${prefix}/${current === 1 ? '' : current}`
    navigate(url)
  }
  render() {
    const { current, ...other } = this.props
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '3rem',
        }}
      >
        <CustomPagination
          current={!current ? 1 : current + 1}
          {...other}
          onChange={this.handleOnChange}
        />
      </div>
    )
  }
}

export default Pagination
