import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import _ from 'lodash'

import Section from '../Common/Section'
import {
  ContainerCategoires,
  LinkCategory,
  Subtitle,
} from './Categories.styled'

const Categories = ({ filterCategories = [], prefix = '' }) => (
  <StaticQuery
    query={categoriesQuery}
    render={data => {
      const categories = _.get(data, 'allContentfulCategory.edges')

      return (
        <Section>
          <Subtitle>Categorías</Subtitle>
          <ContainerCategoires>
            <LinkCategory
              to={!prefix ? '/' : '/noticias/'}
              active={filterCategories.length > 1 ? 'true' : 'false'}
            >
              Todas
            </LinkCategory>
            {categories.map(({ node }) => (
              <LinkCategory
                key={node.slug}
                to={`${prefix}/${node.slug}/`}
                active={categories.length !== filterCategories.length && _.includes(filterCategories, node.slug) ? 'true' : 'false'}
              >
                {node.title}
              </LinkCategory>
            ))}
          </ContainerCategoires>
        </Section>
      )
    }}
  />
)

export default Categories

const categoriesQuery = graphql`
  query CategoriesQuery {
    allContentfulCategory(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
